import React, {useState} from 'react';
import parse from 'html-react-parser';
import {getCookie, getCookieData, setCookie} from './../utility/cookie';
import ListGroupItem from './ListGroupItem';

export default function CookieBannerAdvanced({settings, groups, setHasCookie, closePortal}) {
	const [showConfig, setShowConfig] = useState(false);

	const hasCookieActive = getCookie();
	const groupList = settings.advanced.groups;
	const groupsInCookie = getCookieData();

	let cookieGroups = groupList.map(group => {
		if (hasCookieActive && groupsInCookie.includes(group.groupKey)) {
			return {...group, checked: true};
		}

		return group;
	});

	const handleSelectedGroups = (groupKey, event) => {
		const selectedGroups = cookieGroups.map(group => {
			if (groupKey === group.groupKey) {
				return {...group, checked: event.target.checked};
			}

			return group;
		});

		cookieGroups = selectedGroups;
	};

	const handleSetSelectedGroupsCookie = (groups) => {
		if (!groups.length) {
			groups = cookieGroups.filter(group => group.checked).map(group => group.groupKey);
		}

		setHasCookie(setCookie(groups));
		closePortal();
	};

	const groupListing = cookieGroups.map(groupSettings => {
		let isChecked = groupSettings.checked;

		if (hasCookieActive) {
			isChecked = groupsInCookie.includes(groupSettings.groupKey);
		}

		return <ListGroupItem
			key={groupSettings.groupKey}
			groupSettings={groupSettings}
			checked={isChecked}
			handleSelectedGroups={handleSelectedGroups}
		/>;
	});

	return (
		<div className="AdvancedCookieBannerApp cookie__banner">
			<div className={`cookie__banner--content ${showConfig ? 'hide--content' : ''}`}>
				<div className="cookie__text">{parse(settings.consentText)}</div>
				<div className="cookie__row--navigation">
					<button className="btn btn-primary cookie__row--button-advanced"
							onClick={() => setShowConfig(true)}>
						{settings.advanced.advancedLabel}
					</button>
					<button className="btn btn-primary cookie__row--button-default"
							onClick={() => handleSetSelectedGroupsCookie(groups)}>
						{settings.acceptLabel}
					</button>
				</div>
			</div>
			{showConfig &&
				<div className="cookie__banner--config">
					<h1 className="cookie__banner--title">{settings.advanced.title}</h1>
					<div className="cookie__banner--description">{parse(settings.advanced.description)}</div>
					<div className="cookie__banner--list-groups">
						<ul className="cookie__banner--list">{groupListing}</ul>
					</div>
					<div className="cookie__banner--note">{parse(settings.advanced.note)}</div>
					<div className="cookie__row--navigation">
						<button className="btn btn-primary cookie__row--button-cancel"
								onClick={() => setShowConfig(false)}>
							{settings.advanced.cancelLabel}
						</button>
						<button className="btn btn-primary cookie__row--button-save"
								onClick={handleSetSelectedGroupsCookie}>
							{settings.advanced.saveLabel}
						</button>
					</div>
				</div>
			}
		</div>
	);
};
