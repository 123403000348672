import React from 'react';
import parse from 'html-react-parser';
import {setCookie} from '../utility/cookie';

export default function CookieBanner({settings, groups, setHasCookie, closePortal}) {
	const handleSetSelectedGroupsCookie = (groups) => {
		setHasCookie(setCookie(groups));
		closePortal();
	};

	return (
		<div className="AdvancedCookieBannerApp cookie__banner">
			<div className="cookie__banner--content">
				<div className="cookie__text">
					{parse(settings.consentText)}
				</div>
				<div className="cookie__row--navigation">
					<button className="btn btn-primary cookie__row--button-default"
							onClick={() => handleSetSelectedGroupsCookie(groups)}>{settings.acceptLabel}</button>
				</div>
			</div>
		</div>
	);
};
