import React, {useEffect, useRef, useState} from 'react';
import {usePortals} from 'react-portal-hook';
import {getCookie, getCookieData} from './utility/cookie';
import activateScripts from './utility/service';
import './AdvancedCookieBannerApp.css';
import CookieBanner from './components/CookieBanner';
import CookieBannerAdvanced from './components/CookieBannerAdvanced';

export default function AdvancedCookieBannerApp({settings, isAdvanced, showSettingsTrigger, groups}) {
	const [hasCookie, setHasCookie] = useState(false);
	const portalManager = usePortals();
	const portalElement = useRef();

	useEffect(() => {
		const cookieIsSet = getCookie();
		setHasCookie(cookieIsSet);

		if (cookieIsSet) {
			activateScripts(getCookieData());
		} else {
			openModal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasCookie]);

	const openModal = () => {
		if (!isAdvanced) {
			portalManager.open(portal => <CookieBanner
					settings={settings}
					groups={groups}
					setHasCookie={setHasCookie}
					closePortal={portal.close}
				/>,
				{
					appendTo: portalElement.current
				}
			);
		} else {
			portalManager.open(portal => <CookieBannerAdvanced
					settings={settings}
					groups={groups}
					setHasCookie={setHasCookie}
					closePortal={portal.close}
				/>,
				{
					appendTo: portalElement.current
				}
			);
		}
	};

	if (isAdvanced && showSettingsTrigger) {
		const showSettingsTrigger = document.querySelectorAll('[data-nezzo-cookie-show-settings-trigger]');

		showSettingsTrigger.forEach(elem => {
			let newEl = document.createElement('span');

			newEl.innerHTML = elem.dataset.nezzoCookieShowSettingsTrigger;
			newEl.addEventListener('click', openModal);
			newEl.setAttribute('class', 'cookie__banner--trigger');
			elem.replaceWith(newEl);
		});
	}

	return <div ref={portalElement} />;
};
