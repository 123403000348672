import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import {PortalProvider} from 'react-portal-hook';
import AdvancedCookieBannerApp from './AdvancedCookieBannerApp';

const rootElement = document.querySelector('[data-nezzo-cookie]');
const settingsScriptTag = document.querySelector('[data-nezzo-cookie-settings]');
const SETTINGS = JSON.parse(settingsScriptTag ? settingsScriptTag.innerHTML : '[{"groupKey": "default"}]');
const isAdvanced = 'advanced' in SETTINGS;
const showSettingsTrigger = 'showSettingsTrigger' in SETTINGS && SETTINGS.showSettingsTrigger;
const groups = (isAdvanced) ? SETTINGS.advanced.groups.map(group => group.groupKey) : ['default'];

if (rootElement && settingsScriptTag) {
	ReactDOM.render(
		<React.StrictMode>
			<PortalProvider>
				<AdvancedCookieBannerApp
					settings={SETTINGS}
					isAdvanced={isAdvanced}
					showSettingsTrigger={showSettingsTrigger}
					groups={groups} />
			</PortalProvider>
		</React.StrictMode>,
		rootElement
	);
}
