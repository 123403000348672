export default function activateScripts(scriptGroups) {
	if (!scriptGroups) {
		return;
	}

	if (typeof scriptGroups === 'string') {
		scriptGroups = JSON.parse(scriptGroups);
	}

	scriptGroups.forEach(element => {
		const elements = document.querySelectorAll(`[data-nezzo-cookie-group=${element}]`);

		elements.forEach(cookieGroupElement => {
			let newTag;
			const elementAttributes = cookieGroupElement.attributes;

			// Due to an "execution" bug in Firefox, we need a new element.
			// In Firefox, the script is not executed after an update or element cloning.
			// So we add all the original attributes, except the "type" attribute, to the new element.
			if (cookieGroupElement.hasAttribute('data-nezzo-transform-tag')) {
				newTag = document.createElement(cookieGroupElement.dataset.nezzoTransformTag);
			} else {
				newTag = document.createElement('script');
				newTag.setAttribute('type', 'text/javascript');
			}

			for (const elementAttribute of Object.entries(elementAttributes)) {
				if (elementAttribute[1].name !== 'type') {
					newTag.setAttribute(elementAttribute[1].name, elementAttribute[1].value);
				}
			}

			newTag.innerHTML = cookieGroupElement.innerHTML;

			cookieGroupElement.parentNode.insertBefore(newTag, cookieGroupElement);
			cookieGroupElement.remove();
		});
	});
}
