import Cookies from 'js-cookie';

export function getCookieData() {
	// due to a cross-origin error do not json parse here
	return Cookies.get('nezzo-cookie-consent');
}

export function getCookie() {
	const cookieData = getCookieData();

	return cookieData ? cookieData.includes('default') : false;
}

export function setCookie(groups) {
	Cookies.set('nezzo-cookie-consent', groups, {
		path: '/',
		expires: 31
		// sameSite: 'none' and secure: true , currently doesn't work in chrome
	});
	return true;
}
