import React from 'react';
import parse from 'html-react-parser';

export default function ListGroupItem({groupSettings, checked, handleSelectedGroups}) {
	const groupKey = groupSettings.groupKey;

	return (
		<li className={`cookie__banner--item ${groupKey === 'default' ? "default--settings" : ''}`}>
			<div className="custom-control cookie__banner--control">
				<input type="checkbox" className="custom-control-input cookie__banner--control-input"
					   id={groupKey} defaultChecked={checked} disabled={groupSettings.required} onChange={(event) => handleSelectedGroups(groupKey, event)}/>
				<label className="custom-control-label cookie__banner--control-label" htmlFor={groupKey}><span>{groupSettings.title}</span></label>
			</div>
			<div className="cookie__banner--item-description">{parse(groupSettings.description)}</div>
		</li>
	);
};
